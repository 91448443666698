<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-alert
      type="info"
      color="green darken-2"
      :value="true"
      class="mb-4"
      outline
    >
      <strong>
        Do take note :
        <br>
        • The number shown is not final. It will be different according to the current status.
      </strong>
    </v-alert>
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2024-04"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        placeholder="Search"
        clearable
      />
    </v-toolbar>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="activationHeaders"
      :items="activations"
      :loading="loading"
      :search="search"
      item-key="activation"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan }}
          </td>
          <td class="text-xs-center">
            <v-icon
              v-if="props.item.eligible"
              color="success"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error"
            >
              mdi-close
            </v-icon>
          </td>
          <td class="text-xs-center">
            {{ props.item.eligible }}
          </td>
        </tr>
      </template>
      <template
        #footer
      >
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-left">
            Total Token:
          </td>
          <td class="text-xs-center primary white--text">
            {{ totalActivation }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Dealer Mega Campaign'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Campaigns', disabled: false, to: '/campaigns',
        },
        {
          text: title, disabled: true,
        },
      ],
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2020, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      activations: [],
      activationHeaders: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: true,
          value: 'plan',
        },
        {
          text: 'Eligible',
          align: 'center',
          sortable: true,
          value: 'eligible',
        },
        {
          text: 'Token',
          align: 'center',
          sortable: true,
          value: 'eligible',
        },
      ],
      loading: false,
      alert: false,
      alertMessage: null,
      maxDate: '',
      menu: false,
      monthOf: null,
      title,
      totalActivation: 0,
      search: '',
    }
  },
  watch: {
    monthOf: function (val) {
      this.getDealerMegaCampaign(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    getDealerMegaCampaign: function (month) {
      const param = month ? { month } : {}
      this.loading = true
      this.totalActivation = 0
      this.$rest.get('getDealerMegaCampaign.php', createGetParams(param))
        .then(function (response) {
          this.activations = response.data.activation
          this.activations.forEach(item => {
            this.totalActivation += item.eligible * 1
          })
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
        })

      this.loading = false
    },
  },
}
</script>
